export const BlocksWrapper = () => import('../../components/blocks-wrapper/index.vue' /* webpackChunkName: "components/blocks-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/accordion/index.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/btn/index.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const Block = () => import('../../components/block/index.vue' /* webpackChunkName: "components/block" */).then(c => wrapFunctional(c.default || c))
export const Collapse = () => import('../../components/collapse/index.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c))
export const Composer = () => import('../../components/composer/index.vue' /* webpackChunkName: "components/composer" */).then(c => wrapFunctional(c.default || c))
export const Fig = () => import('../../components/fig/index.vue' /* webpackChunkName: "components/fig" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Multimedia = () => import('../../components/multimedia/index.vue' /* webpackChunkName: "components/multimedia" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/page/index.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Pic = () => import('../../components/pic/index.vue' /* webpackChunkName: "components/pic" */).then(c => wrapFunctional(c.default || c))
export const Slice = () => import('../../components/slice/index.vue' /* webpackChunkName: "components/slice" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/spinner/index.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const Txt = () => import('../../components/txt/index.vue' /* webpackChunkName: "components/txt" */).then(c => wrapFunctional(c.default || c))
export const BtnBackToTop = () => import('../../components/btn/back-to-top/index.vue' /* webpackChunkName: "components/btn-back-to-top" */).then(c => wrapFunctional(c.default || c))
export const BtnCircle = () => import('../../components/btn/circle/index.vue' /* webpackChunkName: "components/btn-circle" */).then(c => wrapFunctional(c.default || c))
export const BtnClose = () => import('../../components/btn/close/index.vue' /* webpackChunkName: "components/btn-close" */).then(c => wrapFunctional(c.default || c))
export const BtnCtaPlay = () => import('../../components/btn/cta-play/index.vue' /* webpackChunkName: "components/btn-cta-play" */).then(c => wrapFunctional(c.default || c))
export const BtnGhost = () => import('../../components/btn/ghost/index.vue' /* webpackChunkName: "components/btn-ghost" */).then(c => wrapFunctional(c.default || c))
export const BtnLink = () => import('../../components/btn/link/index.vue' /* webpackChunkName: "components/btn-link" */).then(c => wrapFunctional(c.default || c))
export const BtnLoadMore = () => import('../../components/btn/load-more/index.vue' /* webpackChunkName: "components/btn-load-more" */).then(c => wrapFunctional(c.default || c))
export const BtnMuted = () => import('../../components/btn/muted/index.vue' /* webpackChunkName: "components/btn-muted" */).then(c => wrapFunctional(c.default || c))
export const BtnNav = () => import('../../components/btn/nav/index.vue' /* webpackChunkName: "components/btn-nav" */).then(c => wrapFunctional(c.default || c))
export const BtnPill = () => import('../../components/btn/pill/index.vue' /* webpackChunkName: "components/btn-pill" */).then(c => wrapFunctional(c.default || c))
export const BtnSolid = () => import('../../components/btn/solid/index.vue' /* webpackChunkName: "components/btn-solid" */).then(c => wrapFunctional(c.default || c))
export const BtnTag = () => import('../../components/btn/tag/index.vue' /* webpackChunkName: "components/btn-tag" */).then(c => wrapFunctional(c.default || c))
export const AppCurtain = () => import('../../components/app/curtain/index.vue' /* webpackChunkName: "components/app-curtain" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/index.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/index.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const BlockGrid = () => import('../../components/block/grid/index.vue' /* webpackChunkName: "components/block-grid" */).then(c => wrapFunctional(c.default || c))
export const BlockHeader = () => import('../../components/block/header/index.vue' /* webpackChunkName: "components/block-header" */).then(c => wrapFunctional(c.default || c))
export const BlockOrganicSearch = () => import('../../components/block/organic-search/index.vue' /* webpackChunkName: "components/block-organic-search" */).then(c => wrapFunctional(c.default || c))
export const BlockTxt = () => import('../../components/block/txt/index.vue' /* webpackChunkName: "components/block-txt" */).then(c => wrapFunctional(c.default || c))
export const CardShowroom = () => import('../../components/card/showroom/index.vue' /* webpackChunkName: "components/card-showroom" */).then(c => wrapFunctional(c.default || c))
export const DetailsProduct = () => import('../../components/details/product/index.vue' /* webpackChunkName: "components/details-product" */).then(c => wrapFunctional(c.default || c))
export const FormCreateList = () => import('../../components/form/create-list/index.vue' /* webpackChunkName: "components/form-create-list" */).then(c => wrapFunctional(c.default || c))
export const FormDownloadAreaFilters = () => import('../../components/form/download-area-filters/index.vue' /* webpackChunkName: "components/form-download-area-filters" */).then(c => wrapFunctional(c.default || c))
export const FormErrors = () => import('../../components/form/errors/index.vue' /* webpackChunkName: "components/form-errors" */).then(c => wrapFunctional(c.default || c))
export const FormHubspot = () => import('../../components/form/hubspot/index.vue' /* webpackChunkName: "components/form-hubspot" */).then(c => wrapFunctional(c.default || c))
export const FormLanguageMarket = () => import('../../components/form/language-market/index.vue' /* webpackChunkName: "components/form-language-market" */).then(c => wrapFunctional(c.default || c))
export const FormListNotes = () => import('../../components/form/list-notes/index.vue' /* webpackChunkName: "components/form-list-notes" */).then(c => wrapFunctional(c.default || c))
export const FormListPrivacy = () => import('../../components/form/list-privacy/index.vue' /* webpackChunkName: "components/form-list-privacy" */).then(c => wrapFunctional(c.default || c))
export const FormLogout = () => import('../../components/form/logout/index.vue' /* webpackChunkName: "components/form-logout" */).then(c => wrapFunctional(c.default || c))
export const FormOrganicSearch = () => import('../../components/form/organic-search/index.vue' /* webpackChunkName: "components/form-organic-search" */).then(c => wrapFunctional(c.default || c))
export const FormPasswordUpdate = () => import('../../components/form/password-update/index.vue' /* webpackChunkName: "components/form-password-update" */).then(c => wrapFunctional(c.default || c))
export const FormSignin = () => import('../../components/form/signin/index.vue' /* webpackChunkName: "components/form-signin" */).then(c => wrapFunctional(c.default || c))
export const FormSignup = () => import('../../components/form/signup/index.vue' /* webpackChunkName: "components/form-signup" */).then(c => wrapFunctional(c.default || c))
export const FormStagingPassword = () => import('../../components/form/staging-password/index.vue' /* webpackChunkName: "components/form-staging-password" */).then(c => wrapFunctional(c.default || c))
export const GalleryBanner = () => import('../../components/gallery/banner/index.vue' /* webpackChunkName: "components/gallery-banner" */).then(c => wrapFunctional(c.default || c))
export const GalleryCover = () => import('../../components/gallery/cover/index.vue' /* webpackChunkName: "components/gallery-cover" */).then(c => wrapFunctional(c.default || c))
export const GalleryMilestones = () => import('../../components/gallery/milestones/index.vue' /* webpackChunkName: "components/gallery-milestones" */).then(c => wrapFunctional(c.default || c))
export const GalleryMulticolumn = () => import('../../components/gallery/multicolumn/index.vue' /* webpackChunkName: "components/gallery-multicolumn" */).then(c => wrapFunctional(c.default || c))
export const GalleryMultiratio = () => import('../../components/gallery/multiratio/index.vue' /* webpackChunkName: "components/gallery-multiratio" */).then(c => wrapFunctional(c.default || c))
export const GalleryMultislide = () => import('../../components/gallery/multislide/index.vue' /* webpackChunkName: "components/gallery-multislide" */).then(c => wrapFunctional(c.default || c))
export const GallerySlide = () => import('../../components/gallery/slide/index.vue' /* webpackChunkName: "components/gallery-slide" */).then(c => wrapFunctional(c.default || c))
export const GalleryZoom = () => import('../../components/gallery/zoom/index.vue' /* webpackChunkName: "components/gallery-zoom" */).then(c => wrapFunctional(c.default || c))
export const GridCss = () => import('../../components/grid/css/index.vue' /* webpackChunkName: "components/grid-css" */).then(c => wrapFunctional(c.default || c))
export const GridMasonry = () => import('../../components/grid/masonry/index.vue' /* webpackChunkName: "components/grid-masonry" */).then(c => wrapFunctional(c.default || c))
export const InputCheckbox = () => import('../../components/input/checkbox/index.vue' /* webpackChunkName: "components/input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputCounter = () => import('../../components/input/counter/index.vue' /* webpackChunkName: "components/input-counter" */).then(c => wrapFunctional(c.default || c))
export const InputRadio = () => import('../../components/input/radio/index.vue' /* webpackChunkName: "components/input-radio" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/input/select/index.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputTag = () => import('../../components/input/tag/index.vue' /* webpackChunkName: "components/input-tag" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/input/text/index.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const ModalAppointment = () => import('../../components/modal/appointment/index.vue' /* webpackChunkName: "components/modal-appointment" */).then(c => wrapFunctional(c.default || c))
export const ModalConsulting = () => import('../../components/modal/consulting/index.vue' /* webpackChunkName: "components/modal-consulting" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateList = () => import('../../components/modal/create-list/index.vue' /* webpackChunkName: "components/modal-create-list" */).then(c => wrapFunctional(c.default || c))
export const ModalDownloadAreaFilters = () => import('../../components/modal/download-area-filters/index.vue' /* webpackChunkName: "components/modal-download-area-filters" */).then(c => wrapFunctional(c.default || c))
export const ModalFindProductFilters = () => import('../../components/modal/find-product-filters/index.vue' /* webpackChunkName: "components/modal-find-product-filters" */).then(c => wrapFunctional(c.default || c))
export const ModalFinishPicker = () => import('../../components/modal/finish-picker/index.vue' /* webpackChunkName: "components/modal-finish-picker" */).then(c => wrapFunctional(c.default || c))
export const ModalGalleryZoom = () => import('../../components/modal/gallery-zoom/index.vue' /* webpackChunkName: "components/modal-gallery-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalListPrivacy = () => import('../../components/modal/list-privacy/index.vue' /* webpackChunkName: "components/modal-list-privacy" */).then(c => wrapFunctional(c.default || c))
export const ModalListShare = () => import('../../components/modal/list-share/index.vue' /* webpackChunkName: "components/modal-list-share" */).then(c => wrapFunctional(c.default || c))
export const ModalLoading = () => import('../../components/modal/loading/index.vue' /* webpackChunkName: "components/modal-loading" */).then(c => wrapFunctional(c.default || c))
export const ModalMenu = () => import('../../components/modal/menu/index.vue' /* webpackChunkName: "components/modal-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalNeedsUser = () => import('../../components/modal/needs-user/index.vue' /* webpackChunkName: "components/modal-needs-user" */).then(c => wrapFunctional(c.default || c))
export const ModalNewsletter = () => import('../../components/modal/newsletter/index.vue' /* webpackChunkName: "components/modal-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ModalSignin = () => import('../../components/modal/signin/index.vue' /* webpackChunkName: "components/modal-signin" */).then(c => wrapFunctional(c.default || c))
export const ModalUser = () => import('../../components/modal/user/index.vue' /* webpackChunkName: "components/modal-user" */).then(c => wrapFunctional(c.default || c))
export const ModalVideoPlayer = () => import('../../components/modal/video-player/index.vue' /* webpackChunkName: "components/modal-video-player" */).then(c => wrapFunctional(c.default || c))
export const SliceBtnGroupGhost = () => import('../../components/slice/btn-group-ghost/index.vue' /* webpackChunkName: "components/slice-btn-group-ghost" */).then(c => wrapFunctional(c.default || c))
export const SlideImage = () => import('../../components/slide/image/index.vue' /* webpackChunkName: "components/slide-image" */).then(c => wrapFunctional(c.default || c))
export const SlideVideo = () => import('../../components/slide/video/index.vue' /* webpackChunkName: "components/slide-video" */).then(c => wrapFunctional(c.default || c))
export const ToolbarFindProductsSearch = () => import('../../components/toolbar/find-products-search/index.vue' /* webpackChunkName: "components/toolbar-find-products-search" */).then(c => wrapFunctional(c.default || c))
export const ToolbarNav = () => import('../../components/toolbar/nav/index.vue' /* webpackChunkName: "components/toolbar-nav" */).then(c => wrapFunctional(c.default || c))
export const ToolbarPill = () => import('../../components/toolbar/pill/index.vue' /* webpackChunkName: "components/toolbar-pill" */).then(c => wrapFunctional(c.default || c))
export const TransitionFade = () => import('../../components/transition/fade/index.vue' /* webpackChunkName: "components/transition-fade" */).then(c => wrapFunctional(c.default || c))
export const TransitionSlide = () => import('../../components/transition/slide/index.vue' /* webpackChunkName: "components/transition-slide" */).then(c => wrapFunctional(c.default || c))
export const TxtAlternate = () => import('../../components/txt/alternate/index.vue' /* webpackChunkName: "components/txt-alternate" */).then(c => wrapFunctional(c.default || c))
export const TxtHuge = () => import('../../components/txt/huge/index.vue' /* webpackChunkName: "components/txt-huge" */).then(c => wrapFunctional(c.default || c))
export const TxtIubenda = () => import('../../components/txt/iubenda/index.vue' /* webpackChunkName: "components/txt-iubenda" */).then(c => wrapFunctional(c.default || c))
export const TxtQuote = () => import('../../components/txt/quote/index.vue' /* webpackChunkName: "components/txt-quote" */).then(c => wrapFunctional(c.default || c))
export const VectorCollection = () => import('../../components/vector/collection/index.vue' /* webpackChunkName: "components/vector-collection" */).then(c => wrapFunctional(c.default || c))
export const VectorIcon = () => import('../../components/vector/icon/index.vue' /* webpackChunkName: "components/vector-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoBg = () => import('../../components/video/bg/index.vue' /* webpackChunkName: "components/video-bg" */).then(c => wrapFunctional(c.default || c))
export const VideoCtaPlay = () => import('../../components/video/cta-play/index.vue' /* webpackChunkName: "components/video-cta-play" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/video/player/index.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const BtnGroupGhost = () => import('../../components/btn/group/ghost/index.vue' /* webpackChunkName: "components/btn-group-ghost" */).then(c => wrapFunctional(c.default || c))
export const BlockCtaAppointment = () => import('../../components/block/cta/appointment/index.vue' /* webpackChunkName: "components/block-cta-appointment" */).then(c => wrapFunctional(c.default || c))
export const BlockCtaConsulting = () => import('../../components/block/cta/consulting/index.vue' /* webpackChunkName: "components/block-cta-consulting" */).then(c => wrapFunctional(c.default || c))
export const BlockFormConfirmAccount = () => import('../../components/block/form/confirm-account/index.vue' /* webpackChunkName: "components/block-form-confirm-account" */).then(c => wrapFunctional(c.default || c))
export const BlockFormHubspot = () => import('../../components/block/form/hubspot/index.vue' /* webpackChunkName: "components/block-form-hubspot" */).then(c => wrapFunctional(c.default || c))
export const BlockFormSignup = () => import('../../components/block/form/signup/index.vue' /* webpackChunkName: "components/block-form-signup" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryBanner = () => import('../../components/block/gallery/banner/index.vue' /* webpackChunkName: "components/block-gallery-banner" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryCover = () => import('../../components/block/gallery/cover/index.vue' /* webpackChunkName: "components/block-gallery-cover" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryMilestones = () => import('../../components/block/gallery/milestones/index.vue' /* webpackChunkName: "components/block-gallery-milestones" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryMulticolumn = () => import('../../components/block/gallery/multicolumn/index.vue' /* webpackChunkName: "components/block-gallery-multicolumn" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryMultiratio = () => import('../../components/block/gallery/multiratio/index.vue' /* webpackChunkName: "components/block-gallery-multiratio" */).then(c => wrapFunctional(c.default || c))
export const BlockGalleryMultislide = () => import('../../components/block/gallery/multislide/index.vue' /* webpackChunkName: "components/block-gallery-multislide" */).then(c => wrapFunctional(c.default || c))
export const BlockGallerySlide = () => import('../../components/block/gallery/slide/index.vue' /* webpackChunkName: "components/block-gallery-slide" */).then(c => wrapFunctional(c.default || c))
export const BlockGridCertifications = () => import('../../components/block/grid/certifications/index.vue' /* webpackChunkName: "components/block-grid-certifications" */).then(c => wrapFunctional(c.default || c))
export const BlockGridDownloadArea = () => import('../../components/block/grid/download-area/index.vue' /* webpackChunkName: "components/block-grid-download-area" */).then(c => wrapFunctional(c.default || c))
export const BlockGridFindProducts = () => import('../../components/block/grid/find-products/index.vue' /* webpackChunkName: "components/block-grid-find-products" */).then(c => wrapFunctional(c.default || c))
export const BlockGridProjectShowcase = () => import('../../components/block/grid/project-showcase/index.vue' /* webpackChunkName: "components/block-grid-project-showcase" */).then(c => wrapFunctional(c.default || c))
export const BlockHeaderCollection = () => import('../../components/block/header/collection/index.vue' /* webpackChunkName: "components/block-header-collection" */).then(c => wrapFunctional(c.default || c))
export const BlockHeaderDesigner = () => import('../../components/block/header/designer/index.vue' /* webpackChunkName: "components/block-header-designer" */).then(c => wrapFunctional(c.default || c))
export const BlockHeaderProduct = () => import('../../components/block/header/product/index.vue' /* webpackChunkName: "components/block-header-product" */).then(c => wrapFunctional(c.default || c))
export const BlockListProductsList = () => import('../../components/block/list/products-list/index.vue' /* webpackChunkName: "components/block-list-products-list" */).then(c => wrapFunctional(c.default || c))
export const BlockListProductsLists = () => import('../../components/block/list/products-lists/index.vue' /* webpackChunkName: "components/block-list-products-lists" */).then(c => wrapFunctional(c.default || c))
export const BlockLocationHeadquarters = () => import('../../components/block/location/headquarters/index.vue' /* webpackChunkName: "components/block-location-headquarters" */).then(c => wrapFunctional(c.default || c))
export const BlockLocationShowrooms = () => import('../../components/block/location/showrooms/index.vue' /* webpackChunkName: "components/block-location-showrooms" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationCsr = () => import('../../components/block/recirculation/csr/index.vue' /* webpackChunkName: "components/block-recirculation-csr" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationMagazine = () => import('../../components/block/recirculation/magazine/index.vue' /* webpackChunkName: "components/block-recirculation-magazine" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationNewsletter = () => import('../../components/block/recirculation/newsletter/index.vue' /* webpackChunkName: "components/block-recirculation-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationProducts = () => import('../../components/block/recirculation/products/index.vue' /* webpackChunkName: "components/block-recirculation-products" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationSubtree = () => import('../../components/block/recirculation/subtree/index.vue' /* webpackChunkName: "components/block-recirculation-subtree" */).then(c => wrapFunctional(c.default || c))
export const BlockSlideImage = () => import('../../components/block/slide/image/index.vue' /* webpackChunkName: "components/block-slide-image" */).then(c => wrapFunctional(c.default || c))
export const BlockSlideVideo = () => import('../../components/block/slide/video/index.vue' /* webpackChunkName: "components/block-slide-video" */).then(c => wrapFunctional(c.default || c))
export const BlockTxtBox = () => import('../../components/block/txt/box/index.vue' /* webpackChunkName: "components/block-txt-box" */).then(c => wrapFunctional(c.default || c))
export const BlockTxtHuge = () => import('../../components/block/txt/huge/index.vue' /* webpackChunkName: "components/block-txt-huge" */).then(c => wrapFunctional(c.default || c))
export const BlockTxtIntro = () => import('../../components/block/txt/intro/index.vue' /* webpackChunkName: "components/block-txt-intro" */).then(c => wrapFunctional(c.default || c))
export const BlockTxtIubenda = () => import('../../components/block/txt/iubenda/index.vue' /* webpackChunkName: "components/block-txt-iubenda" */).then(c => wrapFunctional(c.default || c))
export const BlockTxtQuote = () => import('../../components/block/txt/quote/index.vue' /* webpackChunkName: "components/block-txt-quote" */).then(c => wrapFunctional(c.default || c))
export const BlockVideoLake = () => import('../../components/block/video/lake/index.vue' /* webpackChunkName: "components/block-video-lake" */).then(c => wrapFunctional(c.default || c))
export const BlockVideoPlayer = () => import('../../components/block/video/player/index.vue' /* webpackChunkName: "components/block-video-player" */).then(c => wrapFunctional(c.default || c))
export const CardDownloadMinimal = () => import('../../components/card/download/minimal/index.vue' /* webpackChunkName: "components/card-download-minimal" */).then(c => wrapFunctional(c.default || c))
export const CardDownloadTraditional = () => import('../../components/card/download/traditional/index.vue' /* webpackChunkName: "components/card-download-traditional" */).then(c => wrapFunctional(c.default || c))
export const CardEditorialMinimal = () => import('../../components/card/editorial/minimal/index.vue' /* webpackChunkName: "components/card-editorial-minimal" */).then(c => wrapFunctional(c.default || c))
export const CardLauncherDecorative = () => import('../../components/card/launcher/decorative/index.vue' /* webpackChunkName: "components/card-launcher-decorative" */).then(c => wrapFunctional(c.default || c))
export const CardLauncherNormal = () => import('../../components/card/launcher/normal/index.vue' /* webpackChunkName: "components/card-launcher-normal" */).then(c => wrapFunctional(c.default || c))
export const CardLauncherTraditional = () => import('../../components/card/launcher/traditional/index.vue' /* webpackChunkName: "components/card-launcher-traditional" */).then(c => wrapFunctional(c.default || c))
export const CardProductTraditional = () => import('../../components/card/product/traditional/index.vue' /* webpackChunkName: "components/card-product-traditional" */).then(c => wrapFunctional(c.default || c))
export const FormFindProductFilters = () => import('../../components/form/find-product/filters/index.vue' /* webpackChunkName: "components/form-find-product-filters" */).then(c => wrapFunctional(c.default || c))
export const FormFindProductSearch = () => import('../../components/form/find-product/search/index.vue' /* webpackChunkName: "components/form-find-product-search" */).then(c => wrapFunctional(c.default || c))
export const GridCssDownload = () => import('../../components/grid/css/download/index.vue' /* webpackChunkName: "components/grid-css-download" */).then(c => wrapFunctional(c.default || c))
export const GridCssFig = () => import('../../components/grid/css/fig/index.vue' /* webpackChunkName: "components/grid-css-fig" */).then(c => wrapFunctional(c.default || c))
export const GridCssFinishes = () => import('../../components/grid/css/finishes/index.vue' /* webpackChunkName: "components/grid-css-finishes" */).then(c => wrapFunctional(c.default || c))
export const GridMasonryFig = () => import('../../components/grid/masonry/fig/index.vue' /* webpackChunkName: "components/grid-masonry-fig" */).then(c => wrapFunctional(c.default || c))
export const ListItemFavourite = () => import('../../components/list/item/favourite/index.vue' /* webpackChunkName: "components/list-item-favourite" */).then(c => wrapFunctional(c.default || c))
export const ListItemFavourites = () => import('../../components/list/item/favourites/index.vue' /* webpackChunkName: "components/list-item-favourites" */).then(c => wrapFunctional(c.default || c))
export const ListItemOutOfProduction = () => import('../../components/list/item/out-of-production/index.vue' /* webpackChunkName: "components/list-item-out-of-production" */).then(c => wrapFunctional(c.default || c))
export const ListItemProduct = () => import('../../components/list/item/product/index.vue' /* webpackChunkName: "components/list-item-product" */).then(c => wrapFunctional(c.default || c))
export const TxtTitleHero = () => import('../../components/txt/title/hero/index.vue' /* webpackChunkName: "components/txt-title-hero" */).then(c => wrapFunctional(c.default || c))
export const VectorIllustrationWorld = () => import('../../components/vector/illustration/world/index.vue' /* webpackChunkName: "components/vector-illustration-world" */).then(c => wrapFunctional(c.default || c))
export const VectorLogoFantini = () => import('../../components/vector/logo/fantini/index.vue' /* webpackChunkName: "components/vector-logo-fantini" */).then(c => wrapFunctional(c.default || c))
export const BlockBtnGroupSolid = () => import('../../components/block/btn/group/solid/index.vue' /* webpackChunkName: "components/block-btn-group-solid" */).then(c => wrapFunctional(c.default || c))
export const BlockCardLauncherNormal = () => import('../../components/block/card/launcher/normal/index.vue' /* webpackChunkName: "components/block-card-launcher-normal" */).then(c => wrapFunctional(c.default || c))
export const BlockHeaderMagazineArticle = () => import('../../components/block/header/magazine/article/index.vue' /* webpackChunkName: "components/block-header-magazine-article" */).then(c => wrapFunctional(c.default || c))
export const GridCssDownloadMinimal = () => import('../../components/grid/css/download/minimal/index.vue' /* webpackChunkName: "components/grid-css-download-minimal" */).then(c => wrapFunctional(c.default || c))
export const GridCssDownloadTraditional = () => import('../../components/grid/css/download/traditional/index.vue' /* webpackChunkName: "components/grid-css-download-traditional" */).then(c => wrapFunctional(c.default || c))
export const GridCssEditorialMinimal = () => import('../../components/grid/css/editorial/minimal/index.vue' /* webpackChunkName: "components/grid-css-editorial-minimal" */).then(c => wrapFunctional(c.default || c))
export const GridCssLauncherDecorative = () => import('../../components/grid/css/launcher/decorative/index.vue' /* webpackChunkName: "components/grid-css-launcher-decorative" */).then(c => wrapFunctional(c.default || c))
export const GridCssLauncherTraditional = () => import('../../components/grid/css/launcher/traditional/index.vue' /* webpackChunkName: "components/grid-css-launcher-traditional" */).then(c => wrapFunctional(c.default || c))
export const GridCssProductTraditional = () => import('../../components/grid/css/product/traditional/index.vue' /* webpackChunkName: "components/grid-css-product-traditional" */).then(c => wrapFunctional(c.default || c))
export const GridMasonryProductTraditional = () => import('../../components/grid/masonry/product/traditional/index.vue' /* webpackChunkName: "components/grid-masonry-product-traditional" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
